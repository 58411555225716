import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useDateFormatter } from 'hooks';
import Spinner from 'react-spinners/ClipLoader';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import firebase from 'firebase';
import { colors } from 'styles';
import { SurveyDeploymentStatus } from 'utils/surveyBuilder/enums';
import { selectTriggerAnalyticsState } from 'state/selectors/analytics';
import { surveyDeploymentStatusDisplayText } from 'utils/surveyBuilder';
import getDeploymentStatus from 'utils/surveyBuilder/getDeploymentStatus';
import paths from 'pages/Router/paths';

import classes from './DeploymentsTable.module.scss';

const DeploymentsTable = ({
  deployments,
  surveyId,
  onTriggerAnalytics,
  onDelete,
}) => {
  const { loading } = useSelector(selectTriggerAnalyticsState, shallowEqual);

  const formatDate = useDateFormatter();

  const onDeleteDeploymentHandler = useCallback(
    (deploymentId) => {
      onDelete(deploymentId, surveyId);
    },
    [surveyId]
  );

  return (
    <table className="table">
      <thead>
        <tr className={classes.row}>
          <th>
            <strong>Status</strong>
          </th>
          <th>
            <strong>Wave index</strong>
          </th>
          <th>
            <strong>Wave name</strong>
          </th>
          <th>
            <strong>Version</strong>
          </th>
          <th>
            <strong>Unprocessed</strong>
          </th>
          <th>
            <strong>Processed</strong>
          </th>
          <th>
            <strong>Start date</strong>
          </th>
          <th>
            <strong>End date</strong>
          </th>
          <th>
            <strong>
              Last time
              <br />
              processed
            </strong>
          </th>
          <th>
            <strong>Created at</strong>
          </th>
          <th>
            <strong>Created by</strong>
          </th>
          <th>
            <strong>Options</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        {deployments.map(
          ({
            id,
            url,
            waveIndex,
            waveName,
            waveId,
            waveStatus,
            versionName,
            versionId,
            startDate,
            endDate,
            createdAt,
            createdBy,
            totalResponses,
            unprocessedResponses,
            lastTimeProcessed,
          }) => {
            const startDateParsedToDate =
              firebase.firestore.Timestamp.fromMillis(startDate).toDate();

            const endDateParsedToDate = endDate
              ? firebase.firestore.Timestamp.fromMillis(endDate).toDate()
              : null;

            const endDateFormatted = formatDate(endDate, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            });

            const lastTimeProcessedFormatted = formatDate(lastTimeProcessed, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            });

            const deploymentStatus = getDeploymentStatus(
              startDateParsedToDate,
              endDateParsedToDate,
              waveStatus
            );

            const deploymentIsLoading =
              loading &&
              loading.find((loadingDeploymentId) => loadingDeploymentId === id);

            return (
              <tr className={classes.row}>
                <td>
                  <span
                    className={classNames('tag survey-details__status-tag', {
                      'is-warning':
                        deploymentStatus === SurveyDeploymentStatus.PENDING,
                      'is-success':
                        deploymentStatus === SurveyDeploymentStatus.ACTIVE,
                      'is-danger':
                        deploymentStatus === SurveyDeploymentStatus.CLOSED,
                      'is-light':
                        deploymentStatus === SurveyDeploymentStatus.INACTIVE,
                    })}
                  >
                    {surveyDeploymentStatusDisplayText(deploymentStatus)}
                  </span>
                </td>
                <td>
                  <a
                    href={`${paths.WAVES}/${waveId}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {waveIndex}
                  </a>
                </td>
                <td>{waveName || '-'}</td>
                <td>
                  <a
                    href={`${paths.SURVEYS}/${surveyId}/versions/${versionId}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {versionName}
                  </a>
                </td>
                <td>{unprocessedResponses}</td>
                <td>{totalResponses - unprocessedResponses}</td>
                <td>
                  {formatDate(startDate, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                  })}
                </td>
                <td>{endDate ? endDateFormatted : 'Forever'}</td>
                <td>{lastTimeProcessed ? lastTimeProcessedFormatted : '-'}</td>
                <td>
                  {formatDate(createdAt, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </td>
                <td>{createdBy}</td>
                <td>
                  <div className="buttons" style={{ minWidth: '105px' }}>
                    <a
                      href={`${paths.LIVE_SURVEY}/${url}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="button is-primary is-small"
                      data-tip="View live survey"
                    >
                      <span className="icon is-small">
                        <i className="mdi mdi-eye" />
                      </span>
                    </a>
                    <Link
                      to={`${paths.SURVEYS}/${surveyId}/deployments/${id}`}
                      className="button is-link is-small"
                      data-tip="Edit deployment"
                    >
                      <span className="icon is-small">
                        <i className="mdi mdi-pencil" />
                      </span>
                    </Link>
                    <Link
                      to={paths.ROOT}
                      className="button is-link is-small"
                      data-tip="View deployment details"
                    >
                      <span className="icon is-small">
                        <i className="mdi mdi-chart-areaspline" />
                      </span>
                    </Link>
                    {deploymentStatus === SurveyDeploymentStatus.ACTIVE && (
                      <span
                        data-tip={
                          loading
                            ? 'Wait until current trigger is finished'
                            : 'Trigger processing of the responses'
                        }
                        className={classNames(
                          'button',
                          classes['trigger-analytics-data-tip']
                        )}
                      >
                        <button
                          type="button"
                          className={classNames(
                            'button is-success is-small',
                            classes['trigger-analytics']
                          )}
                          onClick={() => {
                            onTriggerAnalytics({
                              surveyId,
                              deploymentId: id,
                              waveId,
                            });
                          }}
                          disabled={loading}
                        >
                          <span className="icon is-small">
                            {deploymentIsLoading ? (
                              <Spinner size="18" color={colors.white} />
                            ) : (
                              <i className="mdi mdi-play" />
                            )}
                          </span>
                        </button>
                      </span>
                    )}
                    <button
                      type="button"
                      className="button is-danger is-small"
                      data-tip="Delete deployment"
                      onClick={() => onDeleteDeploymentHandler(id)}
                    >
                      <span className="icon is-small">
                        <i className="mdi mdi-delete" />
                      </span>
                    </button>
                    <ReactTooltip />
                  </div>
                </td>
              </tr>
            );
          }
        )}
      </tbody>
    </table>
  );
};

export default DeploymentsTable;
